import React, { useRef, useEffect, useState } from 'react'
import Particles from './particles'
import isMinViewport from '../utils/is-min-viewport'

import block0 from './images/story-particles/block_0.png'
import block1 from './images/story-particles/block_1.png'
// import block2 from './images/story-particles/block_2.png'
// import block3 from './images/story-particles/block_3.png'
// import block4 from './images/story-particles/block_4.png'
import block5 from './images/story-particles/block_5.png'
import block6 from './images/story-particles/block_6.png'

export default function StoryParticles ({ count = 20, countMobile = 10 }) {
  const containerRef = useRef()
  const [ready, setReady] = useState(false)
  // const images = [block0, block1, block2, block3, block4, block5, block6]
  const images = [block0, block1, block5, block6]

  useEffect(() => {
    setReady(!!containerRef.current)
  }, [containerRef.current])

  return (
    <div ref={containerRef} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: -1 }}>
      {ready && (
        <Particles
          container={containerRef.current}
          images={images}
          bgColor={'#faf5eb'}
          count={isMinViewport() ? countMobile : count}
          radius={80}
        />
      )}
    </div>
  )
}
